import { SvgIcon } from "@mui/material";
import React from "react";

const InterviewBit = ({ width, height }) => {
  return (
    <SvgIcon sx={{ width, height }}>
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.325 13.7049L22.0105 18.0293L17.6862 13.7049L22.0105 9.38281L26.325 13.7049Z"
          fill="#61DDE1"
        />
        <path
          d="M22.0099 18.4109L17.296 13.7047L22.0099 9L26.7146 13.7047L22.0099 18.4109ZM18.059 13.7047L22.0099 17.648L25.9517 13.7047L22.0099 9.76295L18.059 13.7047Z"
          fill="#606060"
        />
        <path
          d="M22.0101 18.029L17.6766 22.3518L13.3522 18.0274L17.6766 13.7046L22.0101 18.029Z"
          fill="white"
        />
        <path
          d="M17.6772 22.7331L12.9725 18.0284L17.6772 13.3237L22.3804 18.0284L17.6772 22.7331ZM13.7355 18.0284L17.6772 21.9717L21.6205 18.0284L17.6772 14.0867L13.7355 18.0284Z"
          fill="#606060"
        />
        <path
          d="M13.3538 18.0291L9.0304 22.3525L13.3538 26.676L17.6773 22.3525L13.3538 18.0291Z"
          fill="#FFB94D"
        />
        <path
          d="M13.3536 27.0584L8.64893 22.3536L13.3536 17.6489L18.0599 22.3536L13.3536 27.0584ZM9.41187 22.3536L13.3536 26.2954L17.2969 22.3536L13.3536 18.4119L9.41187 22.3536Z"
          fill="#606060"
        />
        <path
          d="M9.02889 22.3538L4.70544 26.6772L9.02889 31.0007L13.3523 26.6772L9.02889 22.3538Z"
          fill="white"
        />
        <path
          d="M9.03132 31.3816L4.32507 26.6769L9.03132 21.9722L13.736 26.6769L9.03132 31.3816ZM5.08803 26.6769L9.03132 30.6186L12.9731 26.6769L9.03132 22.7336L5.08803 26.6769Z"
          fill="#606060"
        />
        <path
          d="M26.3234 13.7073L22 18.0308L26.3234 22.3542L30.6469 18.0308L26.3234 13.7073Z"
          fill="white"
        />
        <path
          d="M26.3258 22.7331L21.6211 18.0284L26.3258 13.3237L31.0305 18.0284L26.3258 22.7331ZM22.3802 18.0284L26.3235 21.9717L30.2653 18.0284L26.3235 14.0867L22.3802 18.0284Z"
          fill="#606060"
        />
        <path
          d="M22.0022 18.0306L17.6787 22.354L22.0022 26.6775L26.3256 22.354L22.0022 18.0306Z"
          fill="#FFB94D"
        />
        <path
          d="M22.0099 27.0584L17.296 22.3536L22.0099 17.6489L26.7054 22.3536L22.0099 27.0584ZM18.059 22.3536L22.0099 26.2954L25.9425 22.3536L22.0099 18.4119L18.059 22.3536Z"
          fill="#606060"
        />
        <path
          d="M17.6786 22.3538L13.3551 26.6772L17.6786 31.0007L22.002 26.6772L17.6786 22.3538Z"
          fill="white"
        />
        <path
          d="M17.6772 31.3816L12.9725 26.6769L17.6772 21.9722L22.3804 26.6769L17.6772 31.3816ZM13.7355 26.6769L17.6772 30.6186L21.6205 26.6769L17.6772 22.7336L13.7355 26.6769Z"
          fill="#606060"
        />
        <path
          d="M30.6484 18.0306L26.325 22.354L30.6484 26.6775L34.9719 22.354L30.6484 18.0306Z"
          fill="#FFB94D"
        />
        <path
          d="M30.6493 27.0584L25.9431 22.3536L30.6493 17.6489L35.3541 22.3536L30.6493 27.0584ZM26.7061 22.3536L30.6493 26.2954L34.5911 22.3536L30.6493 18.4119L26.7061 22.3536Z"
          fill="#606060"
        />
        <path
          d="M26.3234 22.3538L22 26.6772L26.3234 31.0007L30.6469 26.6772L26.3234 22.3538Z"
          fill="white"
        />
        <path
          d="M26.3258 31.3816L21.6211 26.6769L26.3258 21.9722L31.0305 26.6769L26.3258 31.3816ZM22.3802 26.6769L26.3235 30.6186L30.2653 26.6769L26.3235 22.7336L22.3802 26.6769Z"
          fill="#606060"
        />
        <path
          d="M34.9704 22.3538L30.647 26.6772L34.9704 31.0007L39.2939 26.6772L34.9704 22.3538Z"
          fill="white"
        />
        <path
          d="M34.9713 31.3816L30.2666 26.6769L34.9713 21.9722L39.6775 26.6769L34.9713 31.3816ZM31.0296 26.6769L34.9713 30.6186L38.9146 26.6769L34.9713 22.7336L31.0296 26.6769Z"
          fill="#606060"
        />
        <path
          d="M43.6189 31.0007L39.2953 35.3227L34.9709 31.0007L39.2953 26.6763L43.6189 31.0007Z"
          fill="#61DDE1"
        />
        <path
          d="M39.2953 35.7054L34.5906 31.0006L39.2953 26.2944L44 31.0006L39.2953 35.7054ZM35.3535 31.0006L39.2953 34.9424L43.2386 31.0006L39.2953 27.0574L35.3535 31.0006Z"
          fill="#606060"
        />
        <path
          d="M30.6469 26.6756L26.3235 30.999L30.6469 35.3225L34.9704 30.999L30.6469 26.6756Z"
          fill="#61DDE1"
        />
        <path
          d="M30.6493 35.7054L25.9431 31.0006L30.6493 26.2944L35.3541 31.0006L30.6493 35.7054ZM26.7061 31.0006L30.6493 34.9424L34.5911 31.0006L30.6493 27.0574L26.7061 31.0006Z"
          fill="#606060"
        />
        <path
          d="M22.0007 26.6756L17.6772 30.999L22.0007 35.3225L26.3241 30.999L22.0007 26.6756Z"
          fill="#61DDE1"
        />
        <path
          d="M22.0099 35.7054L17.296 31.0006L22.0099 26.2944L26.7146 31.0006L22.0099 35.7054ZM18.059 31.0006L22.0099 34.9424L25.9517 31.0006L22.0099 27.0574L18.059 31.0006Z"
          fill="#606060"
        />
        <path
          d="M13.3538 26.6756L9.0304 30.999L13.3538 35.3225L17.6773 30.999L13.3538 26.6756Z"
          fill="#61DDE1"
        />
        <path
          d="M13.3536 35.7054L8.64893 31.0006L13.3536 26.2944L18.0599 31.0006L13.3536 35.7054ZM9.41187 31.0006L13.3536 34.9424L17.2969 31.0006L13.3536 27.0574L9.41187 31.0006Z"
          fill="#606060"
        />
        <path
          d="M4.70529 26.6756L0.381836 30.999L4.70529 35.3225L9.02874 30.999L4.70529 26.6756Z"
          fill="#61DDE1"
        />
        <path
          d="M4.70623 35.7054L0 31.0006L4.70623 26.2944L9.41095 31.0006L4.70623 35.7054ZM0.759123 31.0006L4.70241 34.9424L8.64417 31.0006L4.70241 27.0574L0.759123 31.0006Z"
          fill="#606060"
        />
      </svg>
    </SvgIcon>
  );
};

export default InterviewBit;
