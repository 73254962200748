import { SvgIcon } from "@mui/material";
import React from "react";

const NoProblemFound = ({ height, width, props }) => {
  return (
    <SvgIcon sx={{ width, height, ...props }}>
      <svg
        width="336"
        height="240"
        viewBox="0 0 336 240"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M274.285 0H68.5711C59.1033 0 51.4282 7.67512 51.4282 17.1429V222.857C51.4282 232.325 59.1033 240 68.5711 240H274.285C283.753 240 291.428 232.325 291.428 222.857V17.1429C291.428 7.67512 283.753 0 274.285 0Z"
          fill="url(#paint0_linear_408_8200)"
        />
        <g filter="url(#filter0_d_408_8200)">
          <path
            d="M246.857 17.1426H15.4289C10.695 17.1426 6.85742 20.9801 6.85742 25.714V68.5712C6.85742 73.305 10.695 77.1426 15.4289 77.1426H246.857C251.591 77.1426 255.429 73.305 255.429 68.5712V25.714C255.429 20.9801 251.591 17.1426 246.857 17.1426Z"
            fill="white"
          />
        </g>
        <path
          d="M125.143 30.8564H80.5711C77.7308 30.8564 75.4282 33.159 75.4282 35.9993C75.4282 38.8396 77.7308 41.1422 80.5711 41.1422H125.143C127.983 41.1422 130.285 38.8396 130.285 35.9993C130.285 33.159 127.983 30.8564 125.143 30.8564Z"
          fill="#B4DAFF"
        />
        <path
          d="M156 53.1426H80.5711C77.7308 53.1426 75.4282 55.4451 75.4282 58.2854C75.4282 61.1258 77.7308 63.4283 80.5711 63.4283H156C158.84 63.4283 161.143 61.1258 161.143 58.2854C161.143 55.4451 158.84 53.1426 156 53.1426Z"
          fill="#DEE9FC"
        />
        <path
          d="M53.1433 22.2852H20.5719C15.838 22.2852 12.0005 26.1227 12.0005 30.8566V63.428C12.0005 68.1619 15.838 71.9994 20.5719 71.9994H53.1433C57.8772 71.9994 61.7148 68.1619 61.7148 63.428V30.8566C61.7148 26.1227 57.8772 22.2852 53.1433 22.2852Z"
          fill="#1485FD"
        />
        <path
          d="M32.9531 52.7652H36.3816L36.4194 51.4452C36.4194 49.7103 37.5131 49.2577 39.0594 48.7675C40.6105 48.3262 41.9757 47.392 42.9487 46.106C43.9218 44.8199 44.4496 43.2521 44.4525 41.6395C44.4865 40.6549 44.3157 39.6739 43.9509 38.7588C43.5861 37.8436 43.0354 37.0141 42.3334 36.3228C41.6315 35.6315 40.7937 35.0935 39.8731 34.7427C38.9525 34.392 37.969 34.2362 36.9851 34.2852C35.9452 34.2625 34.9116 34.4513 33.9469 34.8401C32.9822 35.2289 32.1065 35.8096 31.3729 36.547C30.6394 37.2844 30.0633 38.1631 29.6795 39.1298C29.2958 40.0966 29.1124 41.1312 29.1405 42.1709H32.6856C32.6722 41.5968 32.7734 41.0258 32.9835 40.4914C33.1936 39.9569 33.5082 39.4698 33.9089 39.0585C34.3097 38.6472 34.7885 38.3201 35.3173 38.0962C35.8461 37.8724 36.4143 37.7563 36.9885 37.7549C37.5063 37.7249 38.0246 37.8067 38.508 37.9948C38.9914 38.1829 39.4287 38.4728 39.79 38.8449C40.1514 39.217 40.4285 39.6626 40.6023 40.1513C40.7762 40.64 40.8428 41.1604 40.7976 41.6772C40.7748 42.5136 40.484 43.3205 39.9679 43.9791C39.4519 44.6377 38.738 45.1132 37.9314 45.3355L36.6868 45.8292C35.4683 46.1228 34.4045 46.8632 33.706 47.9039C33.0076 48.9447 32.7254 50.2097 32.9154 51.4486L32.9531 52.7652ZM34.6502 60.9149C34.9859 60.9268 35.3207 60.8713 35.6346 60.7516C35.9485 60.632 36.2352 60.4506 36.4778 60.2182C36.7204 59.9859 36.914 59.7072 37.047 59.3987C37.1801 59.0903 37.25 58.7582 37.2525 58.4223C37.2495 58.0867 37.1793 57.755 37.046 57.447C36.9128 57.1389 36.7192 56.8606 36.4766 56.6286C36.234 56.3966 35.9475 56.2155 35.6338 56.0961C35.3201 55.9767 34.9857 55.9213 34.6502 55.9332C34.3148 55.9213 33.9803 55.9767 33.6667 56.0961C33.353 56.2155 33.0664 56.3966 32.8238 56.6286C32.5813 56.8606 32.3876 57.1389 32.2544 57.447C32.1211 57.755 32.0509 58.0867 32.0479 58.4223C32.0505 58.7582 32.1203 59.0903 32.2534 59.3987C32.3864 59.7072 32.58 59.9859 32.8226 60.2182C33.0652 60.4506 33.3519 60.632 33.6659 60.7516C33.9798 60.8713 34.3145 60.9268 34.6502 60.9149Z"
          fill="white"
        />
        <g filter="url(#filter1_d_408_8200)">
          <path
            d="M89.1427 90.8574H320.571C322.845 90.8574 325.025 91.7605 326.632 93.3679C328.24 94.9754 329.143 97.1556 329.143 99.4289V142.286C329.143 144.559 328.24 146.739 326.632 148.347C325.025 149.954 322.845 150.857 320.571 150.857H89.1427C86.8694 150.857 84.6893 149.954 83.0818 148.347C81.4743 146.739 80.5713 144.559 80.5713 142.286V99.4289C80.5713 97.1556 81.4743 94.9754 83.0818 93.3679C84.6893 91.7605 86.8694 90.8574 89.1427 90.8574V90.8574Z"
            fill="white"
          />
        </g>
        <path
          d="M198.857 104.571H154.285C151.445 104.571 149.143 106.874 149.143 109.714C149.143 112.554 151.445 114.857 154.285 114.857H198.857C201.697 114.857 204 112.554 204 109.714C204 106.874 201.697 104.571 198.857 104.571Z"
          fill="#B4DAFF"
        />
        <path
          d="M229.714 126.857H154.285C151.445 126.857 149.143 129.16 149.143 132C149.143 134.841 151.445 137.143 154.285 137.143H229.714C232.554 137.143 234.857 134.841 234.857 132C234.857 129.16 232.554 126.857 229.714 126.857Z"
          fill="#DEE9FC"
        />
        <path
          d="M126.857 95.999H94.2858C89.5519 95.999 85.7144 99.8366 85.7144 104.57V137.142C85.7144 141.876 89.5519 145.713 94.2858 145.713H126.857C131.591 145.713 135.429 141.876 135.429 137.142V104.57C135.429 99.8366 131.591 95.999 126.857 95.999Z"
          fill="#1485FD"
        />
        <path
          d="M106.667 126.479H110.096L110.133 125.159C110.133 123.424 111.227 122.972 112.773 122.481C114.324 122.04 115.69 121.106 116.663 119.82C117.636 118.534 118.163 116.966 118.166 115.353C118.2 114.369 118.03 113.388 117.665 112.473C117.3 111.557 116.749 110.728 116.047 110.037C115.345 109.345 114.508 108.807 113.587 108.457C112.666 108.106 111.683 107.95 110.699 107.999C109.659 107.976 108.625 108.165 107.661 108.554C106.696 108.943 105.82 109.523 105.087 110.261C104.353 110.998 103.777 111.877 103.393 112.844C103.01 113.81 102.826 114.845 102.854 115.885H106.4C106.386 115.311 106.487 114.74 106.697 114.205C106.907 113.671 107.222 113.184 107.623 112.772C108.024 112.361 108.502 112.034 109.031 111.81C109.56 111.586 110.128 111.47 110.702 111.469C111.22 111.439 111.738 111.521 112.222 111.709C112.705 111.897 113.143 112.187 113.504 112.559C113.865 112.931 114.142 113.376 114.316 113.865C114.49 114.354 114.557 114.874 114.512 115.391C114.489 116.227 114.198 117.034 113.682 117.693C113.166 118.352 112.452 118.827 111.645 119.049L110.401 119.543C109.182 119.837 108.118 120.577 107.42 121.618C106.721 122.659 106.439 123.924 106.629 125.162L106.667 126.479ZM108.364 134.629C108.7 134.641 109.035 134.585 109.348 134.466C109.662 134.346 109.949 134.164 110.192 133.932C110.434 133.7 110.628 133.421 110.761 133.113C110.894 132.804 110.964 132.472 110.966 132.136C110.963 131.801 110.893 131.469 110.76 131.161C110.627 130.853 110.433 130.575 110.19 130.342C109.948 130.11 109.661 129.929 109.348 129.81C109.034 129.691 108.7 129.635 108.364 129.647C108.029 129.635 107.694 129.691 107.381 129.81C107.067 129.929 106.78 130.11 106.538 130.342C106.295 130.575 106.102 130.853 105.968 131.161C105.835 131.469 105.765 131.801 105.762 132.136C105.764 132.472 105.834 132.804 105.967 133.113C106.1 133.421 106.294 133.7 106.536 133.932C106.779 134.164 107.066 134.346 107.38 134.466C107.694 134.585 108.028 134.641 108.364 134.629Z"
          fill="white"
        />
        <g filter="url(#filter2_d_408_8200)">
          <path
            d="M15.4289 164.571H246.857C249.131 164.571 251.311 165.474 252.918 167.082C254.526 168.689 255.429 170.869 255.429 173.143V216C255.429 218.273 254.526 220.453 252.918 222.061C251.311 223.668 249.131 224.571 246.857 224.571H15.4289C13.1556 224.571 10.9754 223.668 9.36794 222.061C7.76048 220.453 6.85742 218.273 6.85742 216V173.143C6.85742 170.869 7.76048 168.689 9.36794 167.082C10.9754 165.474 13.1556 164.571 15.4289 164.571V164.571Z"
            fill="white"
          />
        </g>
        <path
          d="M125.143 178.285H80.5716C77.7312 178.285 75.4287 180.588 75.4287 183.428C75.4287 186.268 77.7312 188.571 80.5716 188.571H125.143C127.983 188.571 130.286 186.268 130.286 183.428C130.286 180.588 127.983 178.285 125.143 178.285Z"
          fill="#B4DAFF"
        />
        <path
          d="M156 200.571H80.5716C77.7312 200.571 75.4287 202.874 75.4287 205.714C75.4287 208.554 77.7312 210.857 80.5716 210.857H156C158.84 210.857 161.143 208.554 161.143 205.714C161.143 202.874 158.84 200.571 156 200.571Z"
          fill="#DEE9FC"
        />
        <path
          d="M53.1429 169.714H20.5714C15.8376 169.714 12 173.551 12 178.285V210.857C12 215.591 15.8376 219.428 20.5714 219.428H53.1429C57.8767 219.428 61.7143 215.591 61.7143 210.857V178.285C61.7143 173.551 57.8767 169.714 53.1429 169.714Z"
          fill="#1485FD"
        />
        <path
          d="M32.9521 200.195H36.3807L36.4184 198.875C36.4184 197.14 37.5121 196.687 39.0584 196.197C40.6095 195.756 41.9748 194.822 42.9478 193.536C43.9208 192.25 44.4486 190.682 44.4515 189.069C44.4855 188.085 44.3147 187.104 43.9499 186.188C43.5852 185.273 43.0344 184.444 42.3325 183.753C41.6305 183.061 40.7927 182.523 39.8721 182.172C38.9515 181.822 37.9681 181.666 36.9841 181.715C35.9442 181.692 34.9106 181.881 33.9459 182.27C32.9812 182.659 32.1055 183.239 31.372 183.977C30.6384 184.714 30.0623 185.593 29.6785 186.56C29.2948 187.526 29.1114 188.561 29.1395 189.601H32.6847C32.6712 189.026 32.7725 188.456 32.9825 187.921C33.1926 187.387 33.5072 186.899 33.9079 186.488C34.3087 186.077 34.7875 185.75 35.3163 185.526C35.8451 185.302 36.4133 185.186 36.9875 185.185C37.5054 185.155 38.0236 185.236 38.507 185.424C38.9904 185.613 39.4277 185.902 39.7891 186.275C40.1505 186.647 40.4275 187.092 40.6014 187.581C40.7752 188.07 40.8418 188.59 40.7967 189.107C40.7738 189.943 40.483 190.75 39.967 191.409C39.4509 192.067 38.737 192.543 37.9304 192.765L36.6858 193.259C35.4673 193.552 34.4035 194.293 33.705 195.334C33.0066 196.374 32.7244 197.639 32.9144 198.878L32.9521 200.195ZM34.6492 208.345C34.985 208.357 35.3197 208.301 35.6336 208.181C35.9475 208.062 36.2342 207.88 36.4768 207.648C36.7194 207.416 36.913 207.137 37.0461 206.828C37.1791 206.52 37.249 206.188 37.2515 205.852C37.2485 205.516 37.1783 205.185 37.0451 204.877C36.9118 204.569 36.7182 204.29 36.4756 204.058C36.2331 203.826 35.9465 203.645 35.6328 203.526C35.3191 203.406 34.9847 203.351 34.6492 203.363C34.3138 203.351 33.9794 203.406 33.6657 203.526C33.352 203.645 33.0654 203.826 32.8229 204.058C32.5803 204.29 32.3867 204.569 32.2534 204.877C32.1201 205.185 32.0499 205.516 32.047 205.852C32.0495 206.188 32.1194 206.52 32.2524 206.828C32.3855 207.137 32.579 207.416 32.8216 207.648C33.0642 207.88 33.351 208.062 33.6649 208.181C33.9788 208.301 34.3135 208.357 34.6492 208.345Z"
          fill="white"
        />
        <defs>
          <filter
            id="filter0_d_408_8200"
            x="0.857422"
            y="14.1426"
            width="260.571"
            height="72"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="3" />
            <feGaussianBlur stdDeviation="3" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_408_8200"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_408_8200"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_408_8200"
            x="74.5713"
            y="87.8574"
            width="260.571"
            height="72"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="3" />
            <feGaussianBlur stdDeviation="3" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_408_8200"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_408_8200"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_408_8200"
            x="0.857422"
            y="161.571"
            width="260.571"
            height="72"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="3" />
            <feGaussianBlur stdDeviation="3" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_408_8200"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_408_8200"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_408_8200"
            x1="171.428"
            y1="0"
            x2="171.428"
            y2="240"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#E3ECFA" />
            <stop offset="1" stop-color="#DAE7FF" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default NoProblemFound;
