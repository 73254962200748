export const ProblemPlatforms = {
  GFG: "Geeks for Geeks",
  LEETCODE: "LeetCode",
  CODEFORCES: "CodeForces",
  HACKERRANK: "HackerRank",
  CODECHEF: "CodeChef",
  INTERVIEWBIT: "InterviewBit",
  ATCODER: "AtCoder",
};

export const ProblemLevel = {
  EASY: "Easy",
  MEDIUM: "Medium",
  HARD: "Hard",
};

export const ProblemStatus = {
  SOLVED: "Solved",
  UNSOLVED: "Unsolved",
};

export const ProblemTags = {
  AD_HOC: "Ad-hoc",
  ALGORITHMS: "Algorithms",
  ARRAY: "Array",
  BACKTRACKING: "Backtracking",
  BELLMAN_FORD: "Bellman-Ford Algorithm",
  BFS: "Breadth First Search",
  BINARY_EXPONENTIATION: "Binary Exponentiation",
  BINARY_LIFTING: "Binary Lifting",
  BINARY_SEARCH: "Binary Search",
  BINARY_SEARCH_TREE: "Binary Search Tree",
  BINARY_TREE: "Binary Tree",
  BIT_MAGIC: "Bit Magic",
  BITMASK: "Bitmask",
  BRUTE_FORCE: "Brute Force",
  DATA_STRUCTURES: "Data Structures",
  DFS: "Depth First Search",
  DIGIT_DP: "Digit DP",
  DIJKSTRA: "Dijkstra's Algorithm",
  DISJOINT_SET: "Disjoint Set",
  DYNAMIC_PROGRAMMING: "Dynamic Programming",
  DSU: "Disjoint Set Union",
  EULER_PATH: "Euler Path",
  GAME_THEORY: "Game Theory",
  GRAPH: "Graph",
  GREEDY: "Greedy",
  HASH_MAP: "Hash Map",
  IMPLEMENTATION: "Implementation",
  INORDER_TRAVERSAL: "Inorder Traversal",
  LCS: "Longest Common Subsequence",
  LIS: "Longest Increasing Subsequence",
  LINKED_LIST: "Linked List",
  SWEEP_LINE: "Sweep Line",
  LR_DP: "LR DP",
  MAP: "Map",
  MATH: "Math",
  MATRIX: "Matrix",
  MATRIX_EXPONENTIATION: "Matrix Exponentiation",
  MERGE_SORT: "Merge Sort",
  MINIMUM_SPANNING_TREE: "Minimum Spanning Tree",
  MCM: "Matrix Chain Multiplication",
  MULTISET: "Multiset",
  NUMBER_THEORY: "Number Theory",
  OBSERVATION: "Observation",
  OPTIMISATION: "Optimisation",
  PATTERN_FINDING: "Pattern Finding",
  PREFIX_SUM: "Prefix Sum",
  PRIORITY_QUEUE: "Priority Queue",
  QUEUE: "Queue",
  RECURSION: "Recursion",
  SEGMENT_TREE: "Segment Tree",
  SET: "Set",
  SHORTEST_PATH: "Shortest Path",
  SLIDING_WINDOW: "Sliding Window",
  SORTING: "Sorting",
  STACK: "Stack",
  STRING: "String",
  SUFFIX_SUM: "Suffix Sum",
  TIME_COMPLEXITY: "Time Complexity",
  TOPOLOGICAL_SORT: "Topological Sort",
  TRIE: "Trie",
  TREE: "Tree",
  TREE_DP: "Tree DP",
  TWO_POINTERS: "Two Pointers",
  UNION_FIND: "Union-Find",
  WEIGHT_GRAPH: "Weighted Graph",
  Z_ALGORITHM: "Z Algorithm",
};
