import { SvgIcon } from "@mui/material";
import React from "react";

const CodeChef = ({ width, height }) => {
  return (
    <SvgIcon sx={{ width, height }}>
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.4735 21.899C26.4735 22.8972 26.6977 23.7725 27.1462 24.5248C27.6091 25.2626 28.2095 25.834 28.9473 26.2391C29.6851 26.6297 30.4881 26.825 31.3561 26.825C32.0794 26.825 32.7232 26.731 33.2874 26.5429C33.8516 26.3549 34.358 26.1017 34.8065 25.7834C35.2694 25.4651 35.6673 25.1179 36 24.7418V28.2356C35.3779 28.7274 34.698 29.1108 33.9601 29.3857C33.2368 29.6606 32.3181 29.798 31.2042 29.798C30.0468 29.798 28.969 29.6099 27.9708 29.2338C26.987 28.8577 26.1262 28.3151 25.3884 27.6063C24.6651 26.8974 24.1009 26.0655 23.6958 25.1107C23.3052 24.1414 23.1099 23.0708 23.1099 21.899C23.1099 20.7272 23.3052 19.6638 23.6958 18.709C24.1009 17.7397 24.6651 16.9006 25.3884 16.1918C26.1262 15.4829 26.987 14.9404 27.9708 14.5642C28.969 14.1881 30.0468 14 31.2042 14C32.3181 14 33.2368 14.1374 33.9601 14.4123C34.698 14.6872 35.3779 15.0706 36 15.5624V19.0562C35.6673 18.6801 35.2694 18.3329 34.8065 18.0146C34.358 17.6963 33.8516 17.4432 33.2874 17.2551C32.7232 17.067 32.0794 16.973 31.3561 16.973C30.4881 16.973 29.6851 17.1755 28.9473 17.5806C28.2095 17.9712 27.6091 18.5354 27.1462 19.2732C26.6977 20.0111 26.4735 20.8863 26.4735 21.899Z"
          fill="white"
        />
        <path
          d="M26.4735 21.899C26.4735 22.8972 26.6977 23.7725 27.1462 24.5248C27.6091 25.2626 28.2095 25.834 28.9473 26.2391C29.6851 26.6297 30.4881 26.825 31.3561 26.825C32.0794 26.825 32.7232 26.731 33.2874 26.5429C33.8516 26.3549 34.358 26.1017 34.8065 25.7834C35.2694 25.4651 35.6673 25.1179 36 24.7418V28.2356C35.3779 28.7274 34.698 29.1108 33.9601 29.3857C33.2368 29.6606 32.3181 29.798 31.2042 29.798C30.0468 29.798 28.969 29.6099 27.9708 29.2338C26.987 28.8577 26.1262 28.3151 25.3884 27.6063C24.6651 26.8974 24.1009 26.0655 23.6958 25.1107C23.3052 24.1414 23.1099 23.0708 23.1099 21.899C23.1099 20.7272 23.3052 19.6638 23.6958 18.709C24.1009 17.7397 24.6651 16.9006 25.3884 16.1918C26.1262 15.4829 26.987 14.9404 27.9708 14.5642C28.969 14.1881 30.0468 14 31.2042 14C32.3181 14 33.2368 14.1374 33.9601 14.4123C34.698 14.6872 35.3779 15.0706 36 15.5624V19.0562C35.6673 18.6801 35.2694 18.3329 34.8065 18.0146C34.358 17.6963 33.8516 17.4432 33.2874 17.2551C32.7232 17.067 32.0794 16.973 31.3561 16.973C30.4881 16.973 29.6851 17.1755 28.9473 17.5806C28.2095 17.9712 27.6091 18.5354 27.1462 19.2732C26.6977 20.0111 26.4735 20.8863 26.4735 21.899Z"
          fill="url(#paint0_linear_255_4947)"
        />
        <path
          d="M11.3636 21.899C11.3636 22.8972 11.5878 23.7725 12.0363 24.5248C12.4992 25.2626 13.0996 25.834 13.8375 26.2391C14.5753 26.6297 15.3782 26.825 16.2462 26.825C16.9696 26.825 17.6133 26.731 18.1776 26.5429C18.7418 26.3549 19.2481 26.1017 19.6966 25.7834C20.1595 25.4651 20.5574 25.1179 20.8901 24.7418V28.2356C20.2681 28.7274 19.5881 29.1108 18.8503 29.3857C18.1269 29.6606 17.2083 29.798 16.0943 29.798C14.9369 29.798 13.8592 29.6099 12.8609 29.2338C11.8772 28.8577 11.0164 28.3151 10.2786 27.6063C9.55521 26.8974 8.99099 26.0655 8.58592 25.1107C8.19531 24.1414 8 23.0708 8 21.899C8 20.7272 8.19531 19.6638 8.58592 18.709C8.99099 17.7397 9.55521 16.9006 10.2786 16.1918C11.0164 15.4829 11.8772 14.9404 12.8609 14.5642C13.8592 14.1881 14.9369 14 16.0943 14C17.2083 14 18.1269 14.1374 18.8503 14.4123C19.5881 14.6872 20.2681 15.0706 20.8901 15.5624V19.0562C20.5574 18.6801 20.1595 18.3329 19.6966 18.0146C19.2481 17.6963 18.7418 17.4432 18.1776 17.2551C17.6133 17.067 16.9696 16.973 16.2462 16.973C15.3782 16.973 14.5753 17.1755 13.8375 17.5806C13.0996 17.9712 12.4992 18.5354 12.0363 19.2732C11.5878 20.0111 11.3636 20.8863 11.3636 21.899Z"
          fill="white"
        />
        <path
          d="M11.3636 21.899C11.3636 22.8972 11.5878 23.7725 12.0363 24.5248C12.4992 25.2626 13.0996 25.834 13.8375 26.2391C14.5753 26.6297 15.3782 26.825 16.2462 26.825C16.9696 26.825 17.6133 26.731 18.1776 26.5429C18.7418 26.3549 19.2481 26.1017 19.6966 25.7834C20.1595 25.4651 20.5574 25.1179 20.8901 24.7418V28.2356C20.2681 28.7274 19.5881 29.1108 18.8503 29.3857C18.1269 29.6606 17.2083 29.798 16.0943 29.798C14.9369 29.798 13.8592 29.6099 12.8609 29.2338C11.8772 28.8577 11.0164 28.3151 10.2786 27.6063C9.55521 26.8974 8.99099 26.0655 8.58592 25.1107C8.19531 24.1414 8 23.0708 8 21.899C8 20.7272 8.19531 19.6638 8.58592 18.709C8.99099 17.7397 9.55521 16.9006 10.2786 16.1918C11.0164 15.4829 11.8772 14.9404 12.8609 14.5642C13.8592 14.1881 14.9369 14 16.0943 14C17.2083 14 18.1269 14.1374 18.8503 14.4123C19.5881 14.6872 20.2681 15.0706 20.8901 15.5624V19.0562C20.5574 18.6801 20.1595 18.3329 19.6966 18.0146C19.2481 17.6963 18.7418 17.4432 18.1776 17.2551C17.6133 17.067 16.9696 16.973 16.2462 16.973C15.3782 16.973 14.5753 17.1755 13.8375 17.5806C13.0996 17.9712 12.4992 18.5354 12.0363 19.2732C11.5878 20.0111 11.3636 20.8863 11.3636 21.899Z"
          fill="url(#paint1_linear_255_4947)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_255_4947"
            x1="23.1099"
            y1="12.5638"
            x2="39.1182"
            y2="28.0005"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#7A4523" />
            <stop offset="1" stop-color="#3F210D" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_255_4947"
            x1="8"
            y1="12.5638"
            x2="24.0084"
            y2="28.0005"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#7A4523" />
            <stop offset="1" stop-color="#3F210D" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default CodeChef;
