import { SvgIcon } from "@mui/material";
import React from "react";

const GFG = ({ width, height }) => {
  return (
    <SvgIcon sx={{ width, height }}>
      <svg
        width="44"
        height="23"
        viewBox="0 0 44 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M43.9687 10.1497H25.7903C25.9562 8.74406 26.5293 7.41764 27.4392 6.33348C28.3491 5.24931 29.556 4.45476 30.9116 4.04752C32.2671 3.64028 33.7121 3.63813 35.0689 4.04134C36.4256 4.44455 37.6349 5.23547 38.548 6.31693L41.192 3.65803C39.7541 2.05383 37.8767 0.907194 35.7929 0.360332C33.7092 -0.186531 31.5107 -0.109535 29.4703 0.581808C27.4299 1.27315 25.6374 2.54838 24.3152 4.24927C22.993 5.95016 22.1994 8.0018 22.0327 10.1497H21.9694C21.8028 8.0018 21.0091 5.95016 19.687 4.24927C18.3648 2.54838 16.5723 1.27315 14.5319 0.581808C12.4915 -0.109535 10.293 -0.186531 8.20921 0.360332C6.12544 0.907194 4.2481 2.05383 2.81014 3.65803L5.45419 6.31693C6.36729 5.23547 7.57657 4.44455 8.93331 4.04134C10.29 3.63813 11.735 3.64028 13.0906 4.04752C14.4461 4.45476 15.6531 5.24931 16.5629 6.33348C17.4728 7.41764 18.046 8.74406 18.2119 10.1497H0.0327647C0.0114137 10.4302 0.000496535 10.7136 0 10.9996C0.00029325 13.6975 0.991951 16.3013 2.78646 18.3159C4.58097 20.3305 7.05321 21.6155 9.73316 21.9265C12.4131 22.2375 15.1139 21.5529 17.3222 20.0029C19.5304 18.4528 21.092 16.1454 21.7102 13.5192H22.2897C22.9053 16.1479 24.466 18.4584 26.6749 20.0109C28.8837 21.5633 31.5863 22.2491 34.2681 21.9379C36.9499 21.6266 39.4236 20.34 41.2181 18.323C43.0127 16.3059 44.0028 13.6994 43.9999 10.9996C44.0009 10.7136 43.9905 10.4302 43.9687 10.1497ZM11.0007 18.2599C9.51452 18.26 8.06408 17.8041 6.84524 16.9536C5.6264 16.1032 4.69787 14.8992 4.18504 13.5043H17.8149C17.3021 14.8989 16.3738 16.1027 15.1552 16.9531C13.9367 17.8035 12.4866 18.2596 11.0007 18.2599ZM33.0007 18.2599C31.5144 18.26 30.0638 17.8042 28.8449 16.9537C27.6259 16.1033 26.6972 14.8993 26.1843 13.5043H39.8142C39.3014 14.8987 38.3733 16.1023 37.1551 16.9526C35.9369 17.803 34.4864 18.2593 33.0007 18.2599Z"
          fill="#2F8D46"
        />
      </svg>
    </SvgIcon>
  );
};

export default GFG;
