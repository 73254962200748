import { SvgIcon } from "@mui/material";
import React from "react";

const LeetCode = ({ width, height }) => {
  return (
    <SvgIcon sx={{ width, height }}>
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29.9577 32.9273C30.9462 31.9403 32.5463 31.9428 33.5316 32.933C34.5169 33.9233 34.5143 35.5261 33.5259 36.5132L29.1365 40.8963C25.0869 44.9399 18.4835 44.9986 14.3656 41.0322C14.3418 41.0097 12.5082 39.2116 6.4594 33.2803C2.43526 29.3346 2.03436 23.0208 5.82119 18.9662L12.8817 11.4062C16.64 7.38172 23.5681 6.94184 27.8652 10.4169L34.2778 15.603C35.3638 16.4813 35.5334 18.0752 34.6567 19.163C33.7799 20.2509 32.1889 20.4208 31.1029 19.5425L24.6903 14.3565C22.443 12.5391 18.5118 12.7887 16.5726 14.8652L9.512 22.4253C7.66848 24.3991 7.8704 27.579 9.99489 29.6621C14.4367 34.0176 17.8599 37.3741 17.8639 37.3779C20.0047 39.4399 23.4663 39.4092 25.5683 37.3102L29.9577 32.9273Z"
          fill="#FFA116"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.2947 28.5411C17.8991 28.5411 16.7676 27.4077 16.7676 26.0096C16.7676 24.6114 17.8991 23.478 19.2947 23.478H37.9324C39.3281 23.478 40.4595 24.6114 40.4595 26.0096C40.4595 27.4077 39.3281 28.5411 37.9324 28.5411H19.2947Z"
          fill="#B3B3B3"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22.7851 0.802049C23.7386 -0.218938 25.3378 -0.272282 26.357 0.6829C27.3762 1.63808 27.4295 3.24008 26.476 4.26106L9.51208 22.4253C7.66852 24.399 7.8704 27.5788 9.99477 29.662L17.829 37.3441C18.8263 38.3222 18.8434 39.925 17.8671 40.924C16.8908 41.9233 15.2907 41.9403 14.2934 40.9624L6.45917 33.2801C2.43526 29.3342 2.03436 23.0204 5.82135 18.9661L22.7851 0.802049Z"
          fill="black"
        />
      </svg>
    </SvgIcon>
  );
};

export default LeetCode;
