import { SvgIcon } from "@mui/material";
import React from "react";

const Logo = ({ pl = 0 }) => {
  return (
    <SvgIcon sx={{ height: "32px", width: "128px", pl }}>
      <svg
        width="139"
        height="33"
        viewBox="0 0 139 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_453_10350)">
          <path
            d="M8.97522 6.29356L12.3264 1.10801C12.5174 0.764219 13.0309 0.77421 13.2074 1.12515L25.3797 25.3246C25.6194 25.8012 25.2604 26.356 24.7121 26.356H19.2701C18.5134 26.356 17.8228 25.9389 17.491 25.2814L8.91365 9.34608C8.42523 8.37819 8.44814 7.2423 8.97522 6.29356Z"
            fill="url(#paint0_linear_453_10350)"
          />
          <path
            d="M3.60658 19.5917L0.109391 25.1457C-0.191715 25.6239 0.164364 26.2364 0.743475 26.2364H5.02266C6.23162 26.2364 7.35312 25.6271 7.98172 24.6288L11.4789 19.0749C11.78 18.5967 11.4239 17.9842 10.8448 17.9842L6.56562 17.9842C5.35667 17.9842 4.23517 18.5935 3.60658 19.5917Z"
            fill="url(#paint1_linear_453_10350)"
          />
        </g>
        <path
          d="M29 1H32.4028V25.6154H29V1Z"
          fill="url(#paint2_linear_453_10350)"
        />
        <path
          d="M35.871 26.5937H39.2426C39.2634 27.2459 39.4403 27.8245 39.7733 28.3294C40.1063 28.8554 40.5745 29.2656 41.1781 29.5602C41.7816 29.8757 42.4893 30.0335 43.3009 30.0335C43.9877 30.0335 44.6537 29.8652 45.2989 29.5286C45.9441 29.192 46.4644 28.6765 46.8598 27.9822C47.2761 27.288 47.4842 26.3938 47.4842 25.2998V11.0986H50.7934V25.2998C50.7934 27.046 50.4395 28.4872 49.7319 29.6233C49.0451 30.7594 48.1398 31.6009 47.0159 32.1479C45.8921 32.716 44.6746 33 43.3634 33C41.8233 33 40.5017 32.7265 39.3986 32.1795C38.2956 31.6535 37.4423 30.9066 36.8387 29.9389C36.2352 28.9711 35.9126 27.856 35.871 26.5937ZM35.59 18.2623C35.59 16.6634 35.9126 15.3169 36.5578 14.2229C37.203 13.1078 38.0563 12.2558 39.1177 11.6667C40.1999 11.0776 41.355 10.783 42.5829 10.783C43.79 10.783 44.841 11.0881 45.736 11.6982C46.6517 12.2873 47.3697 13.1394 47.89 14.2544C48.4312 15.3485 48.7017 16.6844 48.7017 18.2623C48.7017 19.8192 48.4312 21.1657 47.89 22.3018C47.3697 23.4168 46.6517 24.2794 45.736 24.8895C44.841 25.4786 43.79 25.7732 42.5829 25.7732C41.355 25.7732 40.1999 25.4786 39.1177 24.8895C38.0563 24.3005 37.203 23.4484 36.5578 22.3333C35.9126 21.2183 35.59 19.8613 35.59 18.2623ZM39.0865 18.2623C39.0865 19.2301 39.2842 20.0611 39.6796 20.7554C40.075 21.4287 40.5954 21.9441 41.2405 22.3018C41.9065 22.6384 42.6245 22.8067 43.3946 22.8067C44.0606 22.8067 44.7058 22.6384 45.3301 22.3018C45.9545 21.9441 46.4644 21.4287 46.8598 20.7554C47.2761 20.0822 47.4842 19.2512 47.4842 18.2623C47.4842 17.526 47.3593 16.8843 47.1096 16.3373C46.8806 15.7692 46.5685 15.2959 46.173 14.9172C45.7776 14.5174 45.3405 14.2229 44.8619 14.0335C44.3832 13.8442 43.8941 13.7495 43.3946 13.7495C42.6245 13.7495 41.9065 13.9283 41.2405 14.286C40.5954 14.6226 40.075 15.1275 39.6796 15.8008C39.2842 16.474 39.0865 17.2945 39.0865 18.2623Z"
          fill="url(#paint3_linear_453_10350)"
        />
        <path
          d="M53.882 18.357C53.882 16.8632 54.215 15.5483 54.881 14.4122C55.5678 13.2761 56.494 12.3925 57.6594 11.7613C58.8457 11.1091 60.1673 10.783 61.6242 10.783C63.1018 10.783 64.4234 11.1091 65.5889 11.7613C66.7544 12.3925 67.6701 13.2761 68.3361 14.4122C69.0229 15.5483 69.3663 16.8632 69.3663 18.357C69.3663 19.8297 69.0229 21.1446 68.3361 22.3018C67.6701 23.4379 66.7544 24.332 65.5889 24.9842C64.4234 25.6154 63.1018 25.931 61.6242 25.931C60.1673 25.931 58.8457 25.6154 57.6594 24.9842C56.494 24.332 55.5678 23.4379 54.881 22.3018C54.215 21.1446 53.882 19.8297 53.882 18.357ZM57.3473 18.357C57.3473 19.2617 57.5346 20.0611 57.9092 20.7554C58.2838 21.4287 58.7937 21.9652 59.4389 22.3649C60.0841 22.7436 60.8125 22.9329 61.6242 22.9329C62.4359 22.9329 63.1643 22.7436 63.8095 22.3649C64.4546 21.9652 64.9645 21.4287 65.3392 20.7554C65.7138 20.0611 65.9011 19.2617 65.9011 18.357C65.9011 17.4523 65.7138 16.6529 65.3392 15.9586C64.9645 15.2643 64.4546 14.7278 63.8095 14.3491C63.1643 13.9704 62.4359 13.7811 61.6242 13.7811C60.8125 13.7811 60.0841 13.9704 59.4389 14.3491C58.7937 14.7278 58.2838 15.2643 57.9092 15.9586C57.5346 16.6529 57.3473 17.4523 57.3473 18.357Z"
          fill="url(#paint4_linear_453_10350)"
        />
        <path
          d="M74.3442 15.4852V12.0454H89.9534V15.4852H74.3442ZM87.5496 3.52465H91.2333V25.6154H87.5496V3.52465ZM72.8769 3.52465H76.5607V25.6154H72.8769V3.52465Z"
          fill="url(#paint5_linear_453_10350)"
        />
        <path
          d="M99.2921 19.9349C99.2921 20.9658 99.5106 21.7653 99.9477 22.3333C100.385 22.9014 101.061 23.1854 101.977 23.1854C102.601 23.1854 103.153 23.0487 103.631 22.7751C104.11 22.5016 104.485 22.1229 104.755 21.6391C105.026 21.1552 105.161 20.5871 105.161 19.9349V11.0986H108.533V25.6154H105.161V23.3748C104.703 24.2163 104.11 24.858 103.382 25.2998C102.674 25.7206 101.821 25.931 100.822 25.931C99.2192 25.931 97.9809 25.426 97.1068 24.4162C96.2535 23.4063 95.8268 22.0388 95.8268 20.3136V11.0986H99.2921V19.9349Z"
          fill="url(#paint6_linear_453_10350)"
        />
        <path
          d="M122.079 16.7791C122.079 15.7061 121.861 14.8961 121.423 14.3491C120.986 13.8021 120.32 13.5286 119.425 13.5286C118.78 13.5286 118.218 13.6654 117.74 13.9389C117.261 14.1913 116.886 14.57 116.616 15.075C116.366 15.5588 116.241 16.1269 116.241 16.7791V25.6154H112.838V11.0986H116.241V13.3392C116.699 12.4767 117.282 11.835 117.989 11.4142C118.718 10.9934 119.571 10.783 120.549 10.783C122.173 10.783 123.411 11.288 124.264 12.2978C125.118 13.2867 125.544 14.6437 125.544 16.3688V25.6154H122.079V16.7791Z"
          fill="url(#paint7_linear_453_10350)"
        />
        <path
          d="M127.852 11.0986H136V14.0966H127.852V11.0986ZM130.287 6.04931H133.596V25.6154H130.287V6.04931Z"
          fill="url(#paint8_linear_453_10350)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_453_10350"
            x1="13.1148"
            y1="0.800722"
            x2="13.1148"
            y2="26.356"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#2084FE" />
            <stop offset="1" stop-color="#055AE2" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_453_10350"
            x1="13.1148"
            y1="0.800722"
            x2="13.1148"
            y2="26.356"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#2084FE" />
            <stop offset="1" stop-color="#055AE2" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_453_10350"
            x1="-3.02671"
            y1="-11.0978"
            x2="135.381"
            y2="38.1152"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#2084FE" />
            <stop offset="1" stop-color="#0753CD" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_453_10350"
            x1="-3.02671"
            y1="-11.0978"
            x2="135.381"
            y2="38.1152"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#2084FE" />
            <stop offset="1" stop-color="#0753CD" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_453_10350"
            x1="-3.02671"
            y1="-11.0978"
            x2="135.381"
            y2="38.1152"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#2084FE" />
            <stop offset="1" stop-color="#0753CD" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_453_10350"
            x1="-3.02671"
            y1="-11.0978"
            x2="135.381"
            y2="38.1152"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#2084FE" />
            <stop offset="1" stop-color="#0753CD" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_453_10350"
            x1="-3.02671"
            y1="-11.0978"
            x2="135.381"
            y2="38.1152"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#2084FE" />
            <stop offset="1" stop-color="#0753CD" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_453_10350"
            x1="-3.02671"
            y1="-11.0978"
            x2="135.381"
            y2="38.1152"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#2084FE" />
            <stop offset="1" stop-color="#0753CD" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_453_10350"
            x1="-3.02671"
            y1="-11.0978"
            x2="135.381"
            y2="38.1152"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#2084FE" />
            <stop offset="1" stop-color="#0753CD" />
          </linearGradient>
          <clipPath id="clip0_453_10350">
            <rect
              width="25.5"
              height="25.5"
              fill="white"
              transform="translate(0 0.855957)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default Logo;
